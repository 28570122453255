import React from "react";
import Logo from "./Logo";
import Nav from "./Nav";
import Helmet from "react-helmet";

const Header = () => {
  return (
    <div className="min-w-full flex flex-row justify-between items-center pt-6 pb-6">
      <Helmet>
        <link rel="icon" href="images/favicon.ico" />
      </Helmet>
      <Logo />
      <Nav />
    </div>
  );
};

export default Header;
