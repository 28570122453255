import React from "react";

const NavLink = ({ value, icon, setOpen, block = "center" }) => {
  const handleClick = () => {
    setOpen(false);
    document.getElementById(value.toLowerCase()).scrollIntoView({
      block: block,
      behavior: "smooth",
    });
  };
  return (
    <button
      className="mr-4 transition-all hover:bg-hoverColor px-2 py-4 flex flex-row"
      onClick={() => handleClick()}
    >
      <span className="text-cyan-400 mr-2">{icon} </span>
      {value}
    </button>
  );
};

export default NavLink;
