import React from "react";

const Tools = ({ tools }) => {
  return (
    <ul className="list-disc list-inside ml-2 text-cyan-400">
      {tools.map((tool, index) => (
        <li className="text-sm mb-2" key={index}>
          <span className="text-gray-400">{tool}</span>
        </li>
      ))}
    </ul>
  );
};

export default Tools;
