import React, { useState } from "react";

const VerticalTabs = ({ data }) => {
  const [tab, selectTab] = useState(0);
  const selectedTab = data[tab];

  return (
    <div className="flex-row justify-center items-start hidden md:flex">
      <div className="flex flex-col justify-start items-start m-8">
        {data.map((x, index) => (
          <button
            key={index}
            className={`${
              index === tab
                ? "border-l-2 border-cyan-400"
                : "border-l-2 border-gray-700"
            } mr-4 transition-all hover:bg-hoverColor p-2 w-full text-left ml-2`}
            onClick={() => selectTab(index)}
          >
            {x.tabName}
          </button>
        ))}
      </div>
      <div className="m-8 w-full">
        <h1 className="text-lg">
          {selectedTab.tabData.heading1}{" "}
          <span className="text-cyan-400">
            @ {selectedTab.tabData.heading2}
          </span>
        </h1>
        <h2 className="mb-4">{selectedTab.tabData.subheading}</h2>
        <ul className="list-disc list-inside text-cyan-400 ml-2">
          {selectedTab.tabData.items.map((item, index) => (
            <li className="mb-2" key={index}>
              <span className="text-gray-400">{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default VerticalTabs;
