import React from "react";
import Tools from "./Tools";

const About = ({ data }) => {
  let tools = [];
  const toolsLength = data.tools.length;
  for (let i = 0; i < toolsLength; i += 3) {
    tools.push(<Tools tools={data.tools.slice(i, i + 3)} key={toolsLength*i} />);
  }
  return (
    <div className="flex flex-col-reverse md:flex-row justify-center items-center md:items-start">
      <div className="md:mr-6 md:w-2/4">
        {data.description.map((item, index) => (
          <p className="mb-8 mt-8" key={index}>
            {item}
          </p>
        ))}
        <div className="mt-8">
          <p className="mb-4">
            Here are some technologies that I am currently working with:
          </p>
          <div className="flex flex-row justify-start items-start">{tools}</div>
        </div>
      </div>
      <div className="h-full flex-col justify-center items-center">
        <img src={data.image} alt="" className="md:ml-6 mt-8 mb-4" />
        <div className="flex flex-row justify-around items-center md:ml-6">
          <button className="h-12 w-12 transform transition-transform hover:scale-110">
            <a href={data.github} target="_blank" rel="noreferrer">
              <img src="images/github.png" alt="" />
            </a>
          </button>
          <button className="h-12 w-12 transform transition-transform hover:scale-110">
            <a href={data.linkedin} target="_blank" rel="noreferrer">
              <img src="images/linkedin.png" alt="" />
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
