import React from "react";
import About from "../components/About";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import HorizontalTabs from "../components/HorizontalTabs";
import Project from "../components/Project";
import VerticalTabs from "../components/VerticalTabs";
import experience from "../data/experience.json";
import projects from "../data/featured-projects.json";
import about from "../data/about.json";

const Index = () => {
  const verticalTabExperienceData = experience.map((x) => ({
    tabName: x.company,
    tabData: {
      heading1: x.title,
      heading2: x.companyExtended,
      subheading: `${x.startDate} - ${x.endDate}`,
      items: x.description,
    },
  }));
  return (
    <div className="bg-background text-gray-300 font-montserrat text-lg overflow-hidden">
      <div className="ml-12 mr-12">
        <div className="min-h-scnreen md:h-screen mb-24 md:mb-8">
          <Header />
          <Hero />
        </div>
        <div id="about" className="mb-24">
          <h1 className="text-center text-2xl mb-8">About Me</h1>
          <About data={about} />
        </div>
        <div id="experience" className="mb-24">
          <h1 className="text-center text-2xl mb-8">Experience</h1>
          <HorizontalTabs data={verticalTabExperienceData} />
          <VerticalTabs data={verticalTabExperienceData} />
        </div>
        <div id="projects" className="mb-24">
          <h1 className="text-center text-2xl mb-8">Projects</h1>
          {projects.map((project, index) => (
            <Project data={project} key={index} />
          ))}
        </div>
        <div id="contact" className="flex flex-col items-center justify-center">
          <Contact />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Index;
