import React from "react";

const Footer = () => {
  return (
    <footer className="p-8">
      <a href="https://arbaazmeghani.com/">&copy; 2021 arbaazmeghani.com</a>
    </footer>
  );
};

export default Footer;
