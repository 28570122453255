import React from "react";

const ContactButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="rounded-md border-2 p-4 border-cyan-400 text-cyan-400 shadow-2xl transition-all hover:bg-hoverColor"
    >
      Get In Touch
    </button>
  );
};

export default ContactButton;
