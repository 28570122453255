import React from "react";
import CloseIcon from "../icons/CloseIcon";
import { AnimatePresence, motion } from "framer-motion";

const Notification = ({ sent, setSent, icon, message, className }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {sent && (
        <motion.div
          className={
            className +
            " fixed h-14 w-full md:h-28 md:w-72 bottom-0 right-0 rounded-lg md:mr-4 md:mb-4 opacity-80 shadow-xl"
          }
          onClick={() => setSent(false)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0 }}
        >
          <div className="absolute top-0 right-0 mt-2 mr-2">
            <button
              onClick={() => setSent(false)}
              className="text-gray-600 hover:text-gray-400 transition-colors duration-200"
            >
              <CloseIcon />
            </button>
          </div>
          <div className="flex flex-row justify-start items-center p-4 md:mt-4">
            <span className="mr-4"> {icon} </span>
            {message}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Notification;
