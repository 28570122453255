import React from "react";
import ExternalLinkIcon from "../icons/ExternalLinkIcon";

const Project = ({ data }) => {
  return (
    <div className="flex flex-col md:flex-row justify-start items-start mb-8">
      <img src={data.image} alt="" className="mb-4 md:w-1/3 md:mb-0" />
      <div className="md:pl-8">
        {data.demoLink !== undefined && (
          <a href={data.demoLink} target="_blank" rel="noreferrer">
            {" "}
            <button className="rounded-lg bg-green-600 hover:bg-green-700 p-1 transition-colors duration-200 relative">
              <div className="border-2 border-cyan-400 rounded-full bg-cyan-500 animate-pulse w-2 h-2 absolute top-0 right-0" />
              Demo
            </button>
          </a>
        )}
        <a
          href={data.projectLink}
          target="_blank"
          rel="noreferrer"
          className="flex flex-row transition-colors hover:text-cyan-400 duration-200"
        >
          <h1 className="text-xl mr-2">{data.name}</h1>
          <ExternalLinkIcon />
        </a>
        <h2>Tech Stack: [{data.techStack.join(", ")}]</h2>
        <ul className=" mt-4 list-disc list-inside text-cyan-400 ml-2">
          {data.description.map((bullet, index) => (
            <li key={index}>
              <span className="text-gray-400">{bullet}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Project;
