import React, { useEffect, useState } from "react";
import BriefcaseIcon from "../icons/BriefcaseIcon";
import HamburgerIcon from "../icons/HamburgerIcon";
import MailIcon from "../icons/MailIcon";
import PuzzleIcon from "../icons/PuzzleIcon";
import UserIcon from "../icons/UserIcon";
import Logo from "./Logo";
import NavLink from "./NavLink";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => setIsOpen(false));
  }, []);
  return (
    <>
      <button className="md:hidden" onClick={() => setIsOpen(true)}>
        <HamburgerIcon />
      </button>
      <div
        className={`${
          isOpen ? "overflow-hidden" : "hidden"
        } flex flex-col fixed top-0 left-0 w-full h-full justify-evenly items-center bg-background md:flex md:relative md:flex-row md:justify-between md:items-center md:max-w-min md:max-h-0`}
      >
        <div className="md:hidden">
          <Logo />
          <button
            className="absolute top-0 right-0 mt-8 mr-8"
            onClick={() => setIsOpen(false)}
          >
            X
          </button>
        </div>
        <NavLink value="About" icon={<UserIcon />} setOpen={setIsOpen} />
        <NavLink
          value="Experience"
          icon={<BriefcaseIcon />}
          setOpen={setIsOpen}
        />
        <NavLink
          value="Projects"
          icon={<PuzzleIcon />}
          setOpen={setIsOpen}
          block={"start"}
        />
        <NavLink value="Contact" icon={<MailIcon />} setOpen={setIsOpen} />
        <a href="/resume.pdf" target="_blank" rel="noopener noreferrer">
          <button className="rounded-md border-2 pl-4 pr-4 pt-2 pb-2 border-cyan-400 text-cyan-400 transition-all hover:bg-hoverColor">
            Resume
          </button>
        </a>
      </div>
    </>
  );
};

export default Nav;
