import React from "react";
import ContactButton from "./ContactButton";

const Hero = () => {
  return (
    <div className="flex flex-col justify-center items-start max-w-5xl h-full md:h-2/3 md:ml-24">
      <h1 className="text-4xl md:text-7xl text-gray-100 font-bold mb-4">
        Arbaaz Meghani.
      </h1>
      <h2 className="text-3xl md:text-6xl font-bold mb-8">
        I am a Software Engineer.
      </h2>
      <h3 className="mb-4">
        I specialize in building scalable, fault-tolerant backend systems for
        the web. I am currently working at Capital One where I work on enabling
        Capital One credit card holders to be able to shop with their rewards points
        on various partner sites such as Amazon and PayPal.  I am also a graduate of
        the University of Illinois at Chicago where I studied Computer Science. Additionally, I
        achieved my AWS Certified Solutions Architect - Associate certification in September 2023.
      </h3>
      <ContactButton
        onClick={() =>
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
          })
        }
      />
    </div>
  );
};

export default Hero;
