import React, { useState } from "react";

const HorizontalTabs = ({ data }) => {
  const [tab, selectTab] = useState(0);
  const selectedTab = data[tab];
  return (
    <div className="md:hidden">
      <div className="whitespace-nowrap inline-block h-full w-full overflow-x-scroll">
        {data.map((x, index) => (
          <button
            key={index}
            className={`${
              index === tab
                ? "border-t-2 border-cyan-400"
                : "border-t-2 border-gray-700"
            } mr-4 hover:bg-hoverColor transition-all p-2 h-full text-center ml-2 focus:outline-none`}
            onClick={() => selectTab(index)}
          >
            {x.tabName}
          </button>
        ))}
      </div>
      <div className="mt-4 w-full">
        <h1 className="text-lg">{selectedTab.tabData.heading1} </h1>
        <h1 className="text-lg text-cyan-400">
          @{selectedTab.tabData.heading2}
        </h1>
        <h2 className="mb-4">{selectedTab.tabData.subheading}</h2>
        <ul className="list-disc list-inside text-cyan-400 ml-2">
          {selectedTab.tabData.items.map((item, index) => (
            <li className="mb-2" key={index}>
              <span className="text-gray-400">{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HorizontalTabs;
