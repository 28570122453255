import React from "react";

const Logo = () => {
  return (
    <a href="https://arbaazmeghani.com">
      <img src="images/logo.png" alt="" className="h-12 w-12" />
    </a>
  );
};

export default Logo;
