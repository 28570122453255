import axios from "axios";
import React, { useState } from "react";
import SuccessIcon from "../icons/SuccessIcon";
import ContactButton from "./ContactButton";
import ContactModal from "./ContactModal";
import Notification from "./Notification";

const SEND_EMAIL_URL = "/.netlify/functions/email";

const Contact = () => {
  const [open, setOpen] = useState(false);
  const [sent, setSent] = useState(false);

  const onSubmit = async (email, subject, body) => {
    await axios.post(SEND_EMAIL_URL, { email, subject, body });
    setOpen(false);
    setSent(true);
  };

  return (
    <div className="flex flex-col justify-center items-center mb-24">
      <h1 className="text-2xl mb-8">Contact me</h1>
      <p className="mb-4">Are you interested in working with me?</p>
      <ContactButton onClick={() => setOpen(true)} />
      <ContactModal
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={onSubmit}
      />
      <Notification
        className="bg-green-600 hover:bg-green-700 hover:cursor-pointer transition-colors duration-200"
        sent={sent}
        setSent={setSent}
        icon={<SuccessIcon />}
        message="Your message has been sent!"
      />
    </div>
  );
};

export default Contact;
