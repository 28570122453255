import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import CloseIcon from "../icons/CloseIcon";
import LoadingIcon from "../icons/LoadingIcon";

const ContactModal = ({ open, onClose, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [sending, setSending] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setSending(true);
    await onSubmit(email, subject, body);
    setSending(false);
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="fixed inset-0 flex flex-col justify-center items-center">
          <motion.div
            className="fixed inset-0 h-full w-full bg-gray-900 z-0"
            onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
          />
          <motion.div
            className="w-11/12 h-5/6 md:w-3/5 md:h-4/5 bg-background rounded-lg shadow-2xl z-10 p-8 relative"
            initial={{ opacity: 0, y: "-100vh" }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0, y: "100vh" }}
          >
            <div className="absolute top-0 right-0 mt-4 mr-4">
              <button
                onClick={onClose}
                className="text-gray-600 hover:text-gray-400 transition-colors duration-200"
              >
                <CloseIcon />
              </button>
            </div>
            <form
              className="flex flex-col justify-start items-center h-full w-full"
              onSubmit={submit}
            >
              <h1>Contact Me!</h1>
              <h3 className="text-sm">
                Please fill out the form below to get in touch with me.
              </h3>
              <input
                className="w-5/6 rounded-lg p-2 mt-4 mb-4 bg-background border-2 border-gray-400 outline-none focus:border-cyan-400 transition-colors duration-200"
                type="text"
                placeholder="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                className="w-5/6 rounded-lg p-2 mt-4 mb-4 bg-background border-2 border-gray-400 outline-none focus:border-cyan-400 transition-colors duration-200"
                type="text"
                placeholder="subject"
                required
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <textarea
                className="w-5/6 h-3/6 mt-4 mb-3 rounded-lg p-2 bg-background border-2 border-gray-400 outline-none focus:border-cyan-400 transition-colors duration-200"
                placeholder="body"
                required
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />

              {!sending && (
                <button className="rounded-md p-2 m-8 border-2 border-cyan-400 text-cyan-400 shadow-2xl transition-colors hover:bg-hoverColor">
                  Send
                </button>
              )}

              {sending && (
                <button
                  className="rounded-md p-2 m-8 border-2 border-yellow-400 text-yellow-400 shadow-2xl hover:cursor-not-allowed inline-flex flex-row justify-start items-center"
                  disabled
                >
                  <LoadingIcon />
                  Sending...
                </button>
              )}
            </form>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ContactModal;
